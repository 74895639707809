const House = () => (
    <svg className={''} fill="none" width={29} height={29} xmlns="http://www.w3.org/2000/svg">
        <path
            d="M26.583 25.677h-1.208V12.083a2.418 2.418 0 0 0-.93-1.909L22.958 8.99V6.042a1.208 1.208 0 0 0-1.208-1.209h-4.145l-1.619-1.208a2.416 2.416 0 0 0-2.972 0l-8.459 6.573a2.417 2.417 0 0 0-.93 1.885v13.594H2.417a.906.906 0 0 0 0 1.813h24.166a.907.907 0 0 0 0-1.813ZM7.854 15.407v-1.813a1.208 1.208 0 0 1 1.208-1.209h2.417a1.208 1.208 0 0 1 1.209 1.209v1.812a1.208 1.208 0 0 1-1.209 1.209H9.063a1.208 1.208 0 0 1-1.209-1.209Zm9.667 10.27h-6.042v-3.323a1.813 1.813 0 0 1 1.813-1.812h2.416a1.813 1.813 0 0 1 1.813 1.812v3.323Zm3.625-10.27a1.208 1.208 0 0 1-1.209 1.208h-2.416a1.208 1.208 0 0 1-1.209-1.209v-1.812a1.208 1.208 0 0 1 1.209-1.209h2.416a1.208 1.208 0 0 1 1.209 1.209v1.812Z"
            fill="#E30A18"
        />
    </svg>
)

export default House
