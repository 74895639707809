const Marker = props => (
    <svg width={25} height={29} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g>
            <path
                d="M24.662 9.473a12.193 12.193 0 0 0-4.389-6.912A12.121 12.121 0 0 0 12.514 0 12.121 12.121 0 0 0 4.75 2.551 12.193 12.193 0 0 0 .352 9.46c-1.648 7.296 2.819 13.475 6.821 17.364a7.625 7.625 0 0 0 5.327 2.17c1.99 0 3.901-.778 5.327-2.17 4.016-3.889 8.47-10.054 6.835-17.35ZM12.5 16.543a4.428 4.428 0 0 1-2.475-.738 4.45 4.45 0 0 1-1.648-1.995 4.468 4.468 0 0 1 .95-4.865 4.435 4.435 0 0 1 4.847-.97 4.443 4.443 0 0 1 1.994 1.647 4.475 4.475 0 0 1-.551 5.617 4.446 4.446 0 0 1-3.117 1.318v-.014Z"
                fill="#E30A18"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h25v29H0z" />
            </clipPath>
        </defs>
    </svg>
)

export default Marker
